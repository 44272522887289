import dashboardTabsConfig from '../config/dashboard-tabs.json';

export const useDashboardLandingPage = () => {
  const { $viewport, $i18n } = useNuxtApp();
  const { locale } = $i18n;
  const localePath = useLocalePath();

  const dashboardUrl = computed(() => {
    const dashboardFirstTabPath =
      dashboardTabsConfig?.[locale.value as LocaleTerritory]?.[0]?.path;

    return $viewport.isGreaterThan('sm') && dashboardFirstTabPath
      ? localePath({ name: `shop-dashboard-${dashboardFirstTabPath}` })
      : localePath({ name: 'shop-dashboard' });
  });

  return dashboardUrl.value;
};
